import React from 'react';

const colorMap = {
  '§0': 'text-gray-800',
  '§1': 'text-blue-700',
  '§2': 'text-green-700',
  '§3': 'text-teal-700',
  '§4': 'text-red-700',
  '§5': 'text-fuchsia-700',
  '§6': 'text-yellow-500',
  '§7': 'text-gray-400',
  '§8': 'text-gray-500',
  '§9': 'text-blue-400',
  '§f': 'text-white',
};

const parseMinecraftColors = (text) => {
  // Checking if the text starts with a color code
  const startsWithCode = text.startsWith('§');
  const parts = text.split('§');
  const elements = startsWithCode ? parts.slice(1) : parts;

  return elements.map((part, index) => {
    const code = part.substring(0, 1);
    const content = part.substring(1);
    const tailwindClass = colorMap[`§${code}`] || 'text-black'; // Default color if not defined
    return <span key={index} className={tailwindClass}>{content}</span>;
  });
};

function MinecraftText({ auction }) {
  return (
    <h3 className="text-xl font-bold">
      {parseMinecraftColors(auction.display_name)}
    </h3>
  );
}

export default MinecraftText;
