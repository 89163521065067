import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai'; 
import MinecraftText from './MinecraftText';
import Tooltip from './Tooltip'; // Import the new Tooltip component

function AuctionCard({ auction }) {
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`/viewauction ${auction.auction_uuid}`)
      .then(() => {
        setShowCopiedMessage(true);
        setTimeout(() => setShowCopiedMessage(false), 2000);
      })
      .catch(err => console.error('Error copying to clipboard: ', err));
  };

  const formatNumber = (number) => Number(number).toLocaleString();

  return (
    <div className="relative cursor-pointer flex flex-col justify-between p-4 m-2 rounded-lg shadow border-l-4 border-gray-200 bg-gray-50"
      onClick={copyToClipboard}
    >
      {showCopiedMessage && (
        <div className="absolute top-0 right-0 bg-white text-black text-sm px-2 py-1 rounded transform translate-x-1/2 -translate-y-1/2 shadow-md">
          Copied!
        </div>
      )}
      <div className="mb-1 flex justify-between items-center">
        <MinecraftText auction={auction} />
        <div className="relative">
          <AiOutlineInfoCircle 
            className="text-gray-600 hover:text-gray-800"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
          <Tooltip
            showTooltip={showTooltip}
            content={`Upgrade Costs: ${formatNumber(auction.kat_costs)} \n| Auction Costs: ${formatNumber(auction.pet_costs)} \n| AH Volume: ${formatNumber(auction.volume)}`}
          />
        </div>
      </div>
      <div className="flex justify-between items-center px-2 py-1">
        <p className="font-semibold text-red-700">Price: {formatNumber(auction.total_price)}</p>
        <p className="font-semibold text-green-700">Profit: {formatNumber(auction.profit)}</p>
      </div>
    </div>
  );
}

export default AuctionCard;
