import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar'; // Adjust the import path based on where you placed your Navbar.js file
import Home from './Home'; // Placeholder - create this component
import About from './About'; // Placeholder - create this component
import PetUpgradeFlips from './PetUpgradeFlips';
import CraftingFlips from './CraftingFlips';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/pet-upgrade-flips" element={<PetUpgradeFlips />} />
        <Route path="/crafting-flips" element={<CraftingFlips />} />
      </Routes>
    </Router>
  );
}

export default App;
