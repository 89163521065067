// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tooltip_tooltip__tagtx {
    position: fixed;  /* Positioned relative to the viewport */
    background-color: white;
    border: 1px solid black;  /* Corrected from 'color' to 'border' */
    text-align: left;
    font-size: 0.875rem;  /* 14px assuming root font-size of 16px */
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.95;
    transform: translateX(24px) translateY(-56px); /* Centered and shifted up from the trigger point */
    z-index: 50;
    min-width: max-content;
    max-width: 80%;  /* Limits the width to 80% of the viewport width */
    overflow: hidden;  /* Prevents content from spilling outside */
    text-overflow: ellipsis;  /* Indicates omitted text */
    white-space: normal;  /* Allows text wrapping */
}
`, "",{"version":3,"sources":["webpack://./src/components/Tooltip.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe,GAAG,wCAAwC;IAC1D,uBAAuB;IACvB,uBAAuB,GAAG,uCAAuC;IACjE,gBAAgB;IAChB,mBAAmB,GAAG,yCAAyC;IAC/D,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,6CAA6C,EAAE,mDAAmD;IAClG,WAAW;IACX,sBAAsB;IACtB,cAAc,GAAG,kDAAkD;IACnE,gBAAgB,GAAG,2CAA2C;IAC9D,uBAAuB,GAAG,2BAA2B;IACrD,mBAAmB,GAAG,yBAAyB;AACnD","sourcesContent":[".tooltip {\n    position: fixed;  /* Positioned relative to the viewport */\n    background-color: white;\n    border: 1px solid black;  /* Corrected from 'color' to 'border' */\n    text-align: left;\n    font-size: 0.875rem;  /* 14px assuming root font-size of 16px */\n    padding: 12px;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    opacity: 0.95;\n    transform: translateX(24px) translateY(-56px); /* Centered and shifted up from the trigger point */\n    z-index: 50;\n    min-width: max-content;\n    max-width: 80%;  /* Limits the width to 80% of the viewport width */\n    overflow: hidden;  /* Prevents content from spilling outside */\n    text-overflow: ellipsis;  /* Indicates omitted text */\n    white-space: normal;  /* Allows text wrapping */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `Tooltip_tooltip__tagtx`
};
export default ___CSS_LOADER_EXPORT___;
