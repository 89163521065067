import React, { useEffect, useState } from 'react';
import './App.css';
import CraftCard from './components/CraftCard';

function CraftingFlips() {
  const [auctions, setAuctions] = useState([]);

  useEffect(() => {
    fetch('https://jones0073.pythonanywhere.com/craft_data')
      .then(response => response.json())
      .then(data => {
        setAuctions(data);
      })
      .catch(error => console.error('Error fetching data: ', error));
  }, []);
  

  return (
    <div className="App">
      <div className="px-4 py-6 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {auctions.map(auction => (
            <CraftCard key={auction.uuid} auction={auction} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CraftingFlips;
