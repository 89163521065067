import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-blue-500 text-white shadow-lg">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center">
          <div className="flex space-x-4">
            {/* Logo or Brand name */}
            <div>
              <Link to="/" className="flex items-center py-5 px-2 text-white hover:text-gray-300">
                <span className="font-bold">SkyConomy</span>
              </Link>
            </div>
          </div>
          {/* Primary Nav */}
          <div className="hidden md:flex items-center space-x-1">
            <Link to="/" className="py-5 px-3 hover:underline">Home</Link>
            <Link to="/pet-upgrade-flips" className="py-5 px-3 hover:underline">Pet Upgrade Flips</Link>
            <Link to="/crafting-flips" className="py-5 px-3 hover:underline">Crafting Flips</Link>
            <a href="https://discord.gg/XfdHwtrMCj" target="_blank" rel="noopener noreferrer" className="py-5 px-3 bg-blue-600 hover:bg-blue-700 rounded-md text-white">Join Discord</a>
          </div>
          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="outline-none mobile-menu-button">
              <svg className="w-6 h-6 text-gray-500 hover:text-gray-700"
                   fill="none" 
                   strokeLinecap="round" 
                   strokeLinejoin="round" 
                   strokeWidth="2" 
                   viewBox="0 0 24 24" 
                   stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <Link to="/" className="block py-2 px-4 text-sm hover:bg-blue-700">Home</Link>
        <Link to="/pet-upgrade-flips" className="block py-2 px-4 text-sm hover:bg-blue-700">Pet Upgrade Flips</Link>
        <Link to="/crafting-flips" className="block py-2 px-4 text-sm hover:bg-blue-700">Crafting Flips</Link>
        <a href="https://discord.gg/XfdHwtrMCj" target="_blank" rel="noopener noreferrer" className="block py-2 px-4 text-sm bg-blue-600 hover:bg-blue-700 rounded-md text-white">Join Discord</a>      </div>
    </nav>
  );
};

export default Navbar;
