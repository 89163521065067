// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CraftCard_card__4SVUO {
    padding: 16px;
    margin: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    background-color: #f9fafb;
    border: 1px solid #e2e8f0;
  }
  
  .CraftCard_profit__Kv-ma {
    font-weight: bold;
    color: #4CAF50; /* A softer shade of green */
  }
  
  .CraftCard_costs__-tfJB {
    color: #4a5568;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CraftCard.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,qCAAqC;IACrC,yBAAyB;IACzB,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,cAAc,EAAE,4BAA4B;EAC9C;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".card {\n    padding: 16px;\n    margin: 8px;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n    background-color: #f9fafb;\n    border: 1px solid #e2e8f0;\n  }\n  \n  .profit {\n    font-weight: bold;\n    color: #4CAF50; /* A softer shade of green */\n  }\n  \n  .costs {\n    color: #4a5568;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `CraftCard_card__4SVUO`,
	"profit": `CraftCard_profit__Kv-ma`,
	"costs": `CraftCard_costs__-tfJB`
};
export default ___CSS_LOADER_EXPORT___;
