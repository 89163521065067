import React, { useState, useRef, useEffect } from 'react';
import styles from './Tooltip.module.css';

// Custom hook to calculate the tooltip's optimal position
const useTooltipPosition = (ref) => {
  const [position, setPosition] = useState({});

  useEffect(() => {
    if (ref.current) {
      const tooltipRect = ref.current.getBoundingClientRect();
      const { innerWidth: screenWidth } = window;

      // Adjusts the position to avoid the tooltip overflowing on the right edge of the screen
      let left = tooltipRect.left;
      const tooltipWidth = ref.current.offsetWidth;

      // If tooltip goes beyond right screen edge, shift it to the left of the anchor
      if (tooltipRect.left + tooltipWidth > screenWidth) {
        left = screenWidth - tooltipWidth; // Adjust tooltip position to stay within the screen
      }

      setPosition({
        top: `${tooltipRect.top}px`, // Set top position
        left: `${left}px` // Adjusted left position
      });
    }
  }, [ref]);

  return position;
};

// Tooltip component for showing information on hover
const Tooltip = ({ showTooltip, content }) => {
  const tooltipRef = useRef(null);
  const position = useTooltipPosition(tooltipRef);

  if (!showTooltip) return null;

  return (
    <div 
      ref={tooltipRef} 
      className={styles.tooltip} 
      style={position} 
      role="tooltip" 
      aria-label="Additional Information"
    >
      {content.split('|').map((line, index) => (
        <div key={index} style={{ whiteSpace: 'nowrap' }}>
          {line.trim()}
        </div>
      ))}
    </div>
  );
};

export default Tooltip;
