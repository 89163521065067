import React from 'react';

const Home = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <header className="text-black text-center p-6 shadow-md">
        <h1 className="text-3xl font-bold">Welcome to SkyConomy</h1>
      </header>
      <main className="flex-grow container mx-auto p-4">
        <section className="my-8">
          <h2 className="text-2xl font-semibold text-gray-800">Who are we?</h2>
          <p className="text-gray-600 mt-2">Welcome to SkyConomy, a newly launched project and website with the goal of becoming your main resource for a range of high-quality tools and resources at no cost. We offer accurate, up-to-date data to assist you at every stage of the game.</p>
        </section>
        <section className="my-8">
          <h2 className="text-2xl font-semibold text-gray-800">Constantly Evolving</h2>
          <p className="text-gray-600 mt-2">SkyConomy is always updating. We've recently launched with features like the Kat Upgrade Flipper and the Craft Flipper, and we're not stopping there. More tools such as an Auction Flipper and a Player Information Viewer are coming soon. Keep an eye on our platform as we improve and expand our tools.</p>
        </section>
        <section className="my-8">
          <h2 className="text-2xl font-semibold text-gray-800">Join Our Community</h2>
          <p className="text-gray-600 mt-2">Interested in SkyConomy? Join our Discord server and become part of a community where your ideas can lead to real changes. Share your suggestions for new features or provide feedback on our tools and projects. On our Discord, you can also view development logs, see tools currently in development, and discover features that are planned for the future. Be the first to know what's coming next.</p>
        </section>
        <section className="my-8">
          <h2 className="text-2xl font-semibold text-gray-800">Every Support Counts</h2>
          <p className="text-gray-600 mt-2">Your feedback is crucial to our development. Whether you're giving feedback on new features, suggesting improvements, or sharing your SkyConomy experiences, your involvement helps us evolve. Join us on Discord to influence the direction of our project and enhance SkyConomy.</p>
        </section>
      </main>
      <footer className="bg-gray-800 text-white p-4 text-center">
        <a href="https://discord.gg/XfdHwtrMCj" target="_blank" rel="noopener noreferrer" className="underline hover:no-underline">Join the Discord!</a>
      </footer>
    </div>
  );
}

export default Home;
