import React from 'react';
import styles from './CraftCard.module.css';
import MinecraftText from './MinecraftText';

function CraftCard({ auction }) {
  const formatNumber = (number) => Number(number).toLocaleString();

  return (
    <div className={styles.card}>
      <MinecraftText auction={auction} />
      <p className={styles.profit}>Profit: {formatNumber(auction.profit)}</p>
      <p className={styles.costs}>Craft cost: {formatNumber(auction.craft_cost)} | Sell cost: {formatNumber(auction.average_sell_cost)}</p>
    </div>
  );
}

export default CraftCard;
